import "./style.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Checkout = ({ fun }) => {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    getCart();
  }, []);

  const getCart = async () => {
    const local_cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(local_cart);
  };

  useEffect(() => {
    const totalCost = cart.reduce((acc, item) => {
      const itemTotal = item?.quantity * item?.salePrice || 0;
      return acc + itemTotal;
    }, 0);
    setTotal(totalCost);
  }, [cart]);

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item._id !== productId);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleQuantityChange = (productId, newQuantity) => {
    const updatedCart = cart.map((item) =>
      item._id === productId ? { ...item, quantity: newQuantity } : item
    );
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  return (
    <div className="checkout absolute top-0 left-0 right-0 bottom-0 bg-[#000000a1]">
      <div className="checkout-items-outer absolute z-30 right-0 top-0 w-72 lg:w-96 bg-white">
        <div className="w-full h-full flex flex-col px-2 py-2">
          <div className="close-btn w-full">
            <button type="button" className="outline-none" onClick={fun}>
              X
            </button>
          </div>
          <div className="h-8 border-b-2 flex justify-between items-baseline">
            <p className="items-count text-sm font-normal">
              {cart?.length} items
            </p>
            <p>
              <span className="uppercase font-normal">Total </span>
              <span className="font-semibold">Rs {total}</span>
            </p>
          </div>
          {cart.map((item) => (
            <div key={item._id} className="items-list flex-1 lg:my-4">
              <div className="item flex justify-between items-start">
                <div className="w-1/5 flex flex-col justify-center items-center">
                  <img src={item?.coverImage} alt="item-img" />
                  <button
                    type="button"
                    className="border-b-2 border-black mt-2"
                    onClick={() => {
                      removeFromCart(item._id);
                    }}
                  >
                    <span className="uppercase text-xs font-light">remove</span>
                  </button>
                </div>
                <div className="w-full flex flex-col pl-3 lg:pl-5">
                  <div className="heading-div flex justify-between text-sm font-normal uppercase opacity-65">
                    <span className="item-title">{item?.name}</span>
                    <span className="item-cost">
                      Rs {item?.quantity * item?.salePrice || 0}
                    </span>
                  </div>
                  <form method="post" className="item-form-outer my-2">
                    <div className="qty-div">
                      <span className="text-sm font-normal uppercase opacity-65">
                        quantity:
                      </span>
                      <div className="w-3/4 h-auto relative opacity-55">
                        <span
                          className="absolute z-10 cursor-pointer w-8 text-center border-r-[1px] border-black top-0 left-0.5"
                          onClick={() =>
                            handleQuantityChange(
                              item._id,
                              Math.max(item.quantity - 1, 0)
                            )
                          }
                        >
                          -
                        </span>
                        <input
                          type="number"
                          title="use buttons to change number"
                          name="item-count"
                          id="item-count"
                          className="border-[1px] border-black relative w-full text-center ps-3"
                          min={0}
                          value={item?.quantity}
                          readOnly
                        />
                        <span
                          className="absolute z-10 cursor-pointer w-8 text-center border-l-[1px] border-black top-0 right-0.5"
                          onClick={() =>
                            handleQuantityChange(item._id, item.quantity + 1)
                          }
                        >
                          +
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ))}
          <div className="flex flex-col items-center gap-2 py-8">
            <button
              onClick={() => navigate("/checkout")}
              type="button"
              className={`w-full border-[1px] border-black p-0.5 ${
                cart.length === 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={cart.length === 0}
            >
              <span className="bg-[#00afef] block p-2 w-full text-sm font-light uppercase">
                Check Out
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
