import "./style.css";
import React, { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import axios from "axios";
import logo_03 from "../../assets/svg/Final-Logo-03.svg";

import jazzcash from "../../assets/images/jazzcash.png";
import easypaisa from "../../assets/images/easypaisa.png";
import cashDelivery from "../../assets/images/cash-on-delivery.png";
import credit from "../../assets/images/credit-1.png";

import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../utils/config";
import PrivacyPolicyModal from "../../components/Modal/PrivacyPolicyModal";
import RefundPolicyModal from "../../components/Modal/RefundPolicyModal";
import TermsConditionsModal from "../../components/Modal/TermsConditionsModal";

const angleIcon = (
  <svg
    className="w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 320 512"
  >
    <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
  </svg>
);

const searchIcon = (
  <svg
    className="w-full h-full opacity-55"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
  </svg>
);
const questionIcon = (
  <svg
    className="w-full h-full opacity-55"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z" />
  </svg>
);
const cartIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path d="M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
  </svg>
);

const CheckoutPage = () => {
  const [toggleSummary, setToggleSummary] = useState(true);
  const paymentStatus = "COD";
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [street, setStreet] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [carts, setCarts] = useState([]);
  const [total, setTotal] = useState();
  const [isLogin, setIsLogin] = useState(false);
  const [cart, setCart] = useState([]);

  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleOpenPrivacyModal = () => setIsPrivacyModalOpen(true);
  const handleClosePrivacyModal = () => setIsPrivacyModalOpen(false);

  const handleOpenTermsModal = () => setIsTermsModalOpen(true);
  const handleCloseTermsModal = () => setIsTermsModalOpen(false);

  const handleOpenRefundModal = () => setIsRefundModalOpen(true);
  const handleCloseRefundModal = () => setIsRefundModalOpen(false);

  let userId = "";
  useEffect(() => {
    getCarts();
  }, []);
  const getCarts = async () => {
    const local_cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCarts(local_cart);
  };
  useEffect(() => {
    const totalCost = carts.reduce((acc, cart) => {
      const itemTotal = cart?.quantity * cart?.salePrice || 0;
      return acc + itemTotal;
    }, 0);
    const total = carts.reduce((acc, cart) => acc + cart.quantity, 0);
    setTotalQuantity(total);
    setTotal(totalCost);
  }, [carts]);

  const signIn = async () => {
    const data = {
      phoneNumber: phone,
      password: password,
    };
    await axios
      .post(`${BASE_URL}customer/sign_in`, data)
      .then((response) => {
        if (response.status === 200) {
          userId = response.data.customer._id;
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const signUp = async () => {
    const data = {
      fullName: firstName + " " + lastName,
      email,
      phoneNumber: phone,
      streetAddress: street + ", " + apartment,
      cityName: city,
      postalCode: "-",
      status: true,
    };
    await axios
      .post(`${BASE_URL}guest_customer/create_guest_customer`, data)
      .then((response) => {
        if (response.status === 201) {
          userId = response.data.guestCustomer._id;
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const createOrder = async (data) => {
    await axios
      .post(`${BASE_URL}order/create_order_for_guest_customer`, data)
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhone("");
          setPassword("");
          setApartment("");
          setStreet("");
          setCity("");
          setZip("");

          alert(response.data.success_message);
          removeFromCart();
          navigate("/");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const removeFromCart = (productId) => {
    const updatedCart = cart.filter((item) => item._id !== productId);
    setCart(updatedCart);
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };

  const handleOrder = async (e) => {
    e.preventDefault();

    try {
      if (isLogin) {
        await signIn().then(() => {
          let orderDetails = [];
          carts.map((cart) => {
            orderDetails.push({
              product: cart?._id,
              quantity: cart?.quantity,
              amount: cart?.quantity * cart?.salePrice || 0,
            });
          });
          const data = {
            totalAmount: total,
            totalQuantity,
            customer: userId,
            paymentStatus,
            orderDetails: orderDetails,
            isGuestCustomer: false,
          };
          createOrder(data);
        });
      } else {
        await signUp().then(() => {
          let orderDetails = [];
          carts.map((cart) => {
            orderDetails.push({
              product: cart?._id,
              quantity: cart?.quantity,
              amount: cart?.quantity * cart?.salePrice || 0,
            });
          });
          const data = {
            totalAmount: total,
            totalQuantity,
            customer: userId,
            paymentStatus,
            orderDetails: orderDetails,
            isGuestCustomer: true,
          };
          createOrder(data);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="checkout-page">
      <div className="flex flex-col-reverse lg:flex-row justify-center items-stretch min-h-screen">
        <div className="w-full md:w-3/4 mx-auto lg:w-1/2 md:flex flex-col items-end sm:py-6 lg:py-12">
          <div className="inner w-full px-4 xl:w-4/6 xl:px-0 xl:pe-8">
            <div className="header hidden lg:block">
              <div className="logo-div w-1/4">
                <a href="/">
                  <img src={logo_03} alt="Logo" className="w-full h-auto" />
                </a>
              </div>
            </div>

            <div className="flex justify-center items-center h-12 mt-4 mb-4 space-x-2">
              <button
                type="button"
                className="flex justify-center items-center outline-none rounded-lg"
              >
                <img
                  className="w-auto h-12 md:h-10"
                  src={cashDelivery}
                  alt="cash on delivery"
                />
              </button>
              <button
                type="button"
                className="flex justify-center items-center outline-none rounded-lg"
              >
                <img
                  className="w-auto h-12 md:h-10"
                  src={credit}
                  alt="credit"
                />
              </button>
            </div>

            <p className="text-sm font-light md:pl-1">
              By confirming your order, you agree to accept delivery and adhere
              to our cancellation policy.
            </p>

            <div className="form-div mt-5">
              <form onSubmit={handleOrder}>
                <div className="contact mb-4">
                  <div className="flex justify-between items-center mb-2.5">
                    <p className="text-xl font-medium">Contact</p>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setIsLogin(!isLogin);
                      }}
                      className="text-blue-400 underline underline-offset-2"
                    >
                      {isLogin ? "Sign up" : "Log in"}
                    </a>
                  </div>
                  {!isLogin ? (
                    <div className="contact-input w-full">
                      <input
                        type="text"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        name="contact-email"
                        id="contact-email"
                        className="w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5"
                        placeholder="Email"
                      />
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        className="relative w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5 mt-2"
                        placeholder="Phone Number"
                      />
                    </div>
                  ) : (
                    <div className="contact-input w-full">
                      <input
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                        required
                        name="phone"
                        id="phone"
                        className="w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5"
                        placeholder="Phone Number"
                      />
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        name="password"
                        id="password"
                        className="w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5 mt-2"
                        placeholder="Password"
                      />
                    </div>
                  )}
                </div>

                {!isLogin && (
                  <div className="shipping mb-4">
                    <div className="flex justify-between items-center mb-2.5">
                      <p className="text-xl font-medium">Shipping address</p>
                    </div>
                    <div className="shipping-info w-full flex flex-wrap flex-col space-y-2">
                      <div className="md:flex items-center justify-between space-y-2 md:space-y-0 md:space-x-2.5">
                        <input
                          required
                          type="text"
                          onChange={(e) => setFirstName(e.target.value)}
                          name="first-name"
                          id="first-name"
                          className="w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5"
                          placeholder="First Name"
                        />
                        <input
                          required
                          type="text"
                          onChange={(e) => setLastName(e.target.value)}
                          name="last-name"
                          id="last-name"
                          className="w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5"
                          placeholder="Last Name"
                        />
                      </div>

                      <div className="relative">
                        <input
                          type="text"
                          name="address"
                          id="address"
                          onChange={(e) => setStreet(e.target.value)}
                          required
                          className="relative w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5"
                          placeholder="Address"
                        />
                      </div>
                      <input
                        type="text"
                        name="apartment"
                        id="apartment"
                        required
                        onChange={(e) => setApartment(e.target.value)}
                        className="w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5"
                        placeholder="Apartment, suite, etc. (optional)"
                      />
                      <div className="md:flex items-center justify-between space-y-2 md:space-y-0 md:space-x-2.5">
                        <input
                          type="text"
                          name="city"
                          id="city"
                          required
                          onChange={(e) => setCity(e.target.value)}
                          className="w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5"
                          placeholder="City"
                        />
                        {/* <input
                          type="text"
                          name="zip"
                          id="zip"
                          required
                          onChange={(e) => setZip(e.target.value)}
                          className="w-full rounded-lg border outline-none text-lg font-light px-3 py-2.5"
                          placeholder="ZIP Code"
                        /> */}
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex justify-end">
                  <button
                    type="submit"
                    className="w-full md:w-fit px-4 xl:px-6 h-12 xl:h-[62px] bg-sky-500 rounded-md"
                  >
                    <span className="text-sm xl:text-base uppercase font-medium text-white">
                      Complete Order
                    </span>
                  </button>
                </div>
              </form>
            </div>

            <div className="mt-8 border-t py-4">
              <div className="flex flex-wrap justify-start items-center space-x-2">
                <p
                  className="text-xs md:text-sm font-normal text-blue-400 underline underline-offset-1 cursor-pointer"
                  onClick={handleOpenRefundModal}
                >
                  Refund policy
                </p>
                <p
                  className="text-xs md:text-sm font-normal text-blue-400 underline underline-offset-1 cursor-pointer"
                  onClick={handleOpenPrivacyModal}
                >
                  Privacy policy
                </p>
                <p
                  className="text-xs md:text-sm font-normal text-blue-400 underline underline-offset-1 cursor-pointer"
                  onClick={handleOpenTermsModal}
                >
                  Terms of service
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-3/4 mx-auto lg:w-1/2 md:flex flex-col items-start lg:pt-14 bg-gray-100 border-y border-gray-400 md:border-none relative">
          <div className="after hidden sm:block lg:hidden absolute -top-0.5 -bottom-0.5 -left-72 -right-72 bg-gray-100 border-y border-opacity-55 border-gray-400 -z-10"></div>
          <div className="w-full flex items-center justify-between lg:hidden px-4 py-4">
            <button
              type="button"
              className="order-summry-btn border-none outline-none flex items-center gap-2 text-blue-400"
              onClick={() => {
                setToggleSummary((val) => !val);
              }}
            >
              <span className="icon w-4 h-4">{cartIcon}</span>
              <span className="text-base font-light">Hide order summary</span>
              <span
                className={`icon w-3 h-3 ${
                  toggleSummary ? "rotate-90" : "-rotate-90"
                }`}
              >
                {angleIcon}
              </span>
            </button>
          </div>

          <div
            className={`inner w-full px-4 py-4 lg:py-0 xl:w-4/6 xl:px-0 xl:ps-8 border-opacity-55 border-b md:border-t border-gray-400 md:border-b-0 lg:border-none ${
              toggleSummary ? "block" : "hidden"
            }`}
          >
            <div className="items flex flex-col justify-center items-center gap-4">
              {carts?.length > 0 &&
                carts.map((cart) => (
                  <div className="cart-item w-full flex items-stretch justify-between space-x-4">
                    <div className="image-outer relative w-16 h-16 rounded-md border border-gray-300 bg-gray-200 px-1.5 py-2">
                      <span className="absolute -top-2.5 -right-2.5 w-5 h-5 rounded-full bg-gray-400 text-white text-sm font-normal flex justify-center items-center">
                        {cart?.quantity}
                      </span>
                      <img
                        className="w-full h-full mx-auto"
                        src={cart?.coverImage}
                        alt={cart?.name}
                      />
                    </div>
                    <div className="name-deliver flex-1 flex flex-col items-start justify-center gap-0.5">
                      <p className="name text-sm font-light">{cart?.name}</p>
                    </div>
                    <div className="price flex justify-center items-center">
                      <p className="price font-light">
                        Rs {cart?.quantity * cart?.salePrice || 0}
                      </p>
                    </div>
                  </div>
                ))}
            </div>

            <div className="pricing space-y-1.5 mt-6">
              <div className="flex justify-between items-center">
                <p className="text-lg font-semibold">Total</p>
                <p className="text-lg font-semibold">
                  <span className="font-light pe-4"></span>Rs {total}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden header py-4 w-full px-4 md:w-9/12 mx-auto">
          <div className="logo-div w-1/3">
            <a href="/">
              <img src={logo_03} alt="Logo" className="w-full h-auto" />
            </a>
          </div>
        </div>
      </div>

      <PrivacyPolicyModal
        open={isPrivacyModalOpen}
        handleClose={handleClosePrivacyModal}
      />
      <RefundPolicyModal
        open={isRefundModalOpen}
        handleClose={handleCloseRefundModal}
      />

      <TermsConditionsModal
        open={isTermsModalOpen}
        handleClose={handleCloseTermsModal}
      />
    </div>
  );
};

export default CheckoutPage;
