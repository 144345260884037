import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import bannerImage from "../../assets/images/banner-image.png";
import dropLogoSvg from "../../assets/svg/drop-logo.svg";
import contaminantImg1 from "../../assets/images/contaminant/img-1.png";
import contaminantImg2 from "../../assets/images/contaminant/img-2.png";
import contaminantImg3 from "../../assets/images/contaminant/img-3.png";
import manHoldingBottle from "../../assets/images/man-holding-bottle.png";
import "./style.css";
import { useNavigate } from "react-router-dom";

const FlexContainer = ({ children }) => {
  return (
    <div
      className="container mx-auto flex justify-center gap-6 xl:gap-8 flex-wrap"
      style={{ rowGap: "40px" }}
    >
      {children}
    </div>
  );
};

const FlexItem = ({ index, name, subName, effect }) => {
  return (
    <div className="contaminants-card flex flex-col items-center pt-4 p-1 text-[#1f497f] image-shadow">
      <div className="id-name-chemical flex flex-col justify-center items-center w-full">
        <div className="name text-[80px] opacity-65 font-extralight">
          {index}
        </div>
        <div className="name-text text-center uppercase font-extrabold">
          {name}
        </div>
        <div className="text-[18px] text-center uppercase font-extrabold">
          {subName ? subName : "\u00A0"}
        </div>
      </div>
      <div className="description px-0.5 lg:px-1">
        <p className="text-xl font-light text-center">{effect}</p>
      </div>
    </div>
  );
};

const FlexContainerWithMaxWidth = ({ children }) => {
  return <div className="container mx-auto">{children}</div>;
};

const Water = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const contaminationItems = [
    {
      id: 1,
      name: "Lead",
      subName: "",
      effect:
        "Lead exposure can lead to developmental delays and neurological issues, particularly in children.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 2,
      name: "Arsenic",
      subName: "",
      effect:
        "Arsenic ingestion can cause skin damage, circulatory problems, and an increased risk of cancer.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 3,
      name: "Mercury",
      subName: "",
      effect:
        "Mercury contamination can impair brain development and harm the nervous system.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 4,
      name: "Chlorine",
      subName: "",
      effect:
        "Chlorine, while used to disinfect water, can produce harmful byproducts and cause respiratory issues.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 5,
      name: "Fluoride",
      subName: "",
      effect:
        "High levels of fluoride can lead to dental fluorosis and skeletal fluorosis.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 6,
      name: "Pesticide",
      subName: "",
      effect:
        "Pesticide residues in water can pose health risks, including potential carcinogenic effects.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 7,
      name: "Microplastics",
      subName: "",
      effect:
        "Microplastics can leach harmful chemicals and may cause gastrointestinal issues upon ingestion.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 8,
      name: "Bacteria ",
      subName: "(E. coli)",
      effect:
        "E. coli contamination indicates fecal matter presence and can cause severe gastrointestinal illness.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 9,
      name: "Heavy Metals ",
      subName: "(such as Cadmium)",
      effect:
        "Cadmium exposure can damage kidneys, lungs, and bones, leading to long-term health problems.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 10,
      name: "Nitrates",
      subName: "",
      effect:
        "High nitrate levels can interfere with oxygen transport in the blood, posing risks, especially to infants.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 11,
      name: "Pharmaceuticals",
      subName: "",
      effect:
        "Pharmaceutical residues in water can potentially disrupt hormonal balance and contribute to antibiotic resistance.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
    {
      id: 12,
      name: "Industrial Chemicals",
      subName: "(such as Benzene)",
      effect:
        "Benzene exposure can lead to leukemia and other blood-related disorders, posing serious health risks.",
      num: "153X",
      desc: `EWG'S HEALTH GUIDELINE`,
      utility: "9.18 ppb",
      ewg: "0.06 ppb",
      legalLimit: "NO LEGAL LIMIT",
    },
  ];

  const whatInWaterArr = [
    { element: "Calcium", concentration: "40  -  70" },
    { element: "Magnesium", concentration: "4 -  15" },
    { element: "Sodium", concentration: "10  -  20" },
    { element: "Potassium", concentration: "0.1  -  2" },
    { element: "Chloride", concentration: "77  -  150" },
    { element: "Sulfate", concentration: "12  -  50" },
    { element: "Nitrate", concentration: "0  -  <0.1" },
    { element: "pH", concentration: "6.5  -  8.5" },
    { element: "Total Dissolved Solids (TDS)", concentration: "120  -  250" },
  ];

  const navigate = useNavigate();
  const handleShopOnline = () => {
    navigate("/shop-now");
  };
  const handleStoreLocator = () => {
    navigate("/find-a-store");
  };

  return (
    <div>
      <Header />

      <div className="our-water">
        {/* BANNER START */}

        <div className="banner w-full flex justify-center items-center px-4">
          <div className="container relative mx-auto flex flex-col md:flex-row items-center justify-center -mt-8 z-10">
            {/* Text Section */}
            <div className="txt-div w-11/12 md:w-1/2 flex flex-col justify-center items-center md:items-start mb-6 md:mb-0">
              <h1 className="uppercase">Environmental Sustainability</h1>
              <p className="mt-2 mb-6 uppercase">
                Al Fajar Water cares a lot about the environment. We work hard
                to use energy wisely and reduce waste by recycling and making
                better infrastructure.
              </p>
            </div>

            {/* Image Section */}
            <div className="h-full w-full md:w-1/2 banner-img flex justify-center items-center">
              <img
                src={bannerImage}
                alt="Banner Image"
                className="banner-bottles w-full h-auto md:rounded-lg"
              />
            </div>
          </div>
          <div className="bg-overlay absolute top-0 bottom-0 left-0 right-0 bg-[#00afefb0]"></div>
        </div>

        {/* BANNER ENDS */}

        <div className="text-div w-full flex justify-center items-center">
          <div className="text-div-outer flex flex-col justify-center items-center text-center gap-8">
            <h2 className="uppercase">Al Fajar Water Economic Impact </h2>
            <p>
              {/* Investing 100 PKR in Al Fajar Water yields an economic return of
              1000 PKR, making it a substantial investment.
              <br className="sm:hidden" />
              <br className="sm:hidden" /> */}
              Access to clean water stands out as one of the most influential
              catalysts for driving economic growth known to humanity.
            </p>
            <div className="btn-div flex justify-center items-center gap-4">
              <button type="button" onClick={handleStoreLocator}>
                <span className="uppercase">store locator</span>
              </button>
              <button type="button" onClick={handleShopOnline}>
                <span className="uppercase">shop online</span>
              </button>
            </div>
          </div>
        </div>

        {/* CONTAMINANTS START */}

        <div className="contaminants w-full">
          <div className="flex flex-col gap-6 items-center my-12">
            <h2 className="uppercase text-center">
              Our Water Is 100% Free from These Contaminants
            </h2>

            <div className="contaminants-flex-container">
              <FlexContainerWithMaxWidth>
                <FlexContainer>
                  {contaminationItems.map((item, index) => (
                    <FlexItem
                      key={item.id}
                      index={index < 9 ? `0${index + 1}` : index + 1}
                      name={item.name}
                      subName={item.subName}
                      chemical={item.chemical}
                      effect={item.effect}
                      num={item.num}
                      desc={item.desc}
                      utility={item.utility}
                      ewg={item.ewg}
                      legalLimit={item.legalLimit}
                    />
                  ))}
                </FlexContainer>
              </FlexContainerWithMaxWidth>
            </div>
          </div>
        </div>

        {/* CONTAMINANTS ENDS */}

        {/* CONTAMINANTS-TABLE START */}

        <div className="hidden contaminant-table w-full">
          <div className="container mx-auto">
            <div className="heading-div flex justify-evenly xl:justify-around  gap-8 items-center mb-2">
              <p className="heading-text text-2xl xl:text-4xl font-bold text-[#1f2122] uppercase mt-8">
                CONTAMINANT
              </p>
              <div className="images hidden md:flex gap-8 items-center">
                <span>
                  <img src={contaminantImg1} alt="img-1" />
                </span>
                <span>
                  <img src={contaminantImg2} alt="img-2" />
                </span>
                <span>
                  <img src={contaminantImg3} alt="img-3" />
                </span>
              </div>
            </div>
            <div className="tables-div container flex flex-col justify-center items-center">
              <table className="table-1 top">
                <tr>
                  <th colSpan={4}>contaminants above health guidelines</th>
                </tr>
                <tr>
                  <td>
                    <span>Bromodichloromethane</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Chloroform</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Chromium (hexavalent)</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Dibromoacetic acid</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Dibromochloromethane</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Dichloroacetic acid</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Haloacetic acids (HAA5)</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Haloacetic acids (HAA9)</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Nitrate</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Perfluorooctane sulfonate (PFOS)</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Total trihalomethanes (TTHMs)</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Trichloroacetic acid</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <th colSpan={4}>OTHER CONTAMINANTS DETECTED</th>
                </tr>

                <tr>
                  <td>
                    <span>Atrazine</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Bromoform</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Chromium (total)</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Fluoride</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Manganese</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Molybdenum</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Monochloroacetic acid</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Strontium</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>

                <tr>
                  <td>
                    <span>Vanadium</span>
                  </td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                  <td className="tick">✓</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        {/* CONTAMINANTS-TABLE ENDS */}

        {/* WHAT'S-IN-OUR-WATER START */}

        <div className="whats-in-water">
          <div className="container mx-auto flex flex-col lg:justify-center lg:flex-row relative pt-4">
            <div className="w-full lg:w-1/2 table-div-outer px-4 py-4 md:py-6">
              <h2 className="uppercase text-center lg:text-start mb-6">
                What’s In Our Water
              </h2>
              <div className="table-div w-full 2xl:w-3/4">
                <table className="w-full">
                  <tr className="tr-head text-left text-lg text-white">
                    <th className="uppercase w-3/5">Element</th>
                    <th className="uppercase">Concentration (mg/L)</th>
                  </tr>
                  {whatInWaterArr?.map(({ element, concentration }, index) => {
                    return (
                      <tr key={index} className="text-left">
                        <td className="uppercase w-3/5">{element}</td>
                        <td>{concentration}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
            <div className="w-full lg:w-1/3 xl:w-1/2 image-div relative pt-4 md:pt-6">
              <div className="w-full absolute top-0 left-0 right-0 bottom-0 opacity-25">
                <img
                  src={dropLogoSvg}
                  alt="dropLogoSvg"
                  className="max-h-fit"
                />
              </div>
              <div className="h-full flex justify-center items-end relative">
                <img
                  src={manHoldingBottle}
                  alt="manHoldingBottle"
                  className="w-3/5 lg:w-full lg:object-fill xl:w-3/5"
                />
              </div>
            </div>
          </div>
        </div>

        {/* WHAT'S-IN-OUR-WATER END */}
      </div>

      <Footer />
    </div>
  );
};

export default Water;
