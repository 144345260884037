import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./style.css";
import logo_03 from "../../assets/svg/Final-Logo-03.svg";
// const logo_03 = 'assets/svg/Final-Logo-03.svg';

const Header = () => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menuItems = [
    { name: "Home", url: "/" },
    { name: "Our Mission", url: "/our-mission" },
    { name: "Shop Now", url: "/shop-now" },
    { name: "Donatise", url: "/donatise" },
    { name: "Our Water", url: "/our-water" },
    // { name: "Media/PR", url: "/media-pr" },
    { name: "Find A Store", url: "/find-a-store" },
    { name: "Contact", url: "/contact-us" },
  ];
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header className="bg-white text-black">
      <div className="h-10 flex justify-center items-center bg-black">
        <p className="add-banner-text">FREE DELIVERY ON ALL ORDERS IN LAHORE</p>
      </div>
      <div className="nav-bar 2xl:container mx-auto px-4 flex justify-between items-center ">
        <div className="flex items-center md:justify-end 2xl:justify-start w-2/5 md:w-1/5">
          <a href="/">
            <img
              src={logo_03}
              alt="Logo"
              className="w-full xl:w-52 xl:h-14 xl:mr-4 float-end 2xl:float-start"
            />
          </a>
        </div>
        <nav className="hidden lg:block">
          <ul className="desktop-nav flex space-x-4 pe-4">
            {menuItems.map((item) => (
              <li
                key={item.name}
                className={
                  item.url === location.pathname
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <a href={item.url}>{item.name}</a>
              </li>
            ))}
          </ul>
        </nav>
        <div className="lg:hidden">
          <button
            onClick={toggleMobileMenu}
            className="block text-black focus:outline-none"
          >
            <svg
              className="h-6 w-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6H20M4 12H20M4 18H20"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="mobile-menu absolute left-0 right-0 lg:hidden py-2 sm:py-16">
          <ul className="flex flex-col items-center sm:space-y-2">
            {menuItems.map((item) => (
              <li
                key={item.name}
                className={
                  item.url === location.pathname
                    ? "nav-link active"
                    : "nav-link"
                }
              >
                <a
                  className="block py-2 px-4 hover:bg-gray-700"
                  href={item.url}
                >
                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
