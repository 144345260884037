import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 33.650724,
  lng: 73.041971,
};

const markerPositions = [
  { lat: 33.650724, lng: 73.041971 }, // Example position
];

const GoogleMapComponent = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAdllqRmzsHtHnsnQ6dJ-bA6aFHSeFJxHs",
  });
  const [markers, setMarkers] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    getLocations();
  }, []);
  const getLocations = async () => {
    await axios
      .get(`${BASE_URL}location/get_all_locations`)
      .then((response) => {
        console.log("Response: ", response);
        if (response.status === 200) {
          setMarkers(response.data.locations);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {isLoaded ? (
        <GoogleMap
          center={{ lat: 31.5204, lng: 74.3587 }}
          zoom={12}
          // onClick={() => setActiveMarker(null)}
          mapContainerStyle={{ width: "100%", height: "90vh" }}
        >
          {markers &&
            markers.map(({ _id, address, position }) => (
              <MarkerF
                key={_id}
                position={{ lat: position.latitude, lng: position.longitude }}
                onClick={() => handleActiveMarker(_id)}
                icon={{
                  url: "https://res.cloudinary.com/dv9quby2f/image/upload/v1718040931/t8ndhcsspbi1bbtdficf.png",
                  scaledSize: { width: 20, height: 25 },
                }}
              >
                {activeMarker === _id ? (
                  <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                    <div>
                      <p>{address}</p>
                    </div>
                  </InfoWindowF>
                ) : null}
              </MarkerF>
            ))}
        </GoogleMap>
      ) : null}
    </>
  );
};

export default GoogleMapComponent;
