// src/pages/Map/Map.js
import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import find from "../../assets/images/find.png";
import GoogleMapComponent from "../../components/GoogleMap/GoogleMap";
import "./style.css";

const Map = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="map-us">
        <div className="map-banner w-full h-40 flex justify-center items-center">
          <p className="uppercase desktop-device text-center text-white text-5xl font-extrabold">
            store locator
          </p>
          <p className="uppercase mobile-device text-center text-white md:text-5xl font-extrabold">
            find Al Fajar store near you.
          </p>
        </div>

        <div className="map-search w-full flex items-center">
          <div className="filters-text">
            <p className="search-filter m-5 p-5 text-white md:text-2xl font-extrabold">
              Filters
            </p>
          </div>

          <div className="filters-text-right">
            <form className="search-section">
              <div className="flex">
                <div className="w-full flex">
                  <input
                    type="search"
                    id="search-dropdown"
                    className="w-full z-20 text-base md:text-3xl text-gray-900 bg-gray-50 rounded-e-lg border-s-gray-50 border-s-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
                    placeholder="Lahore"
                    required
                  />
                  <button
                    type="submit"
                    className="search-submit text-base font-medium h-full text-white bg-blue-700 rounded-e-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <img className="image-icon" src={find} alt="item-img" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div id="map-address-outer" className="w-auto">
          <p id="map-address" className="px-0 mb-0">
            <GoogleMapComponent />
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Map;
