// src/pages/ShopNow/ShopNow.js
import "./style.css";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import BannerWithoutImage from "../../components/BannerWithoutImage/BannerWithoutImage";
import ProductCard from "../../components/ProductCard/ProductsCard";

const ShopNow = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}product/get_all_products`);
      if (response.status === 200) {
        setProducts(response.data.products);
        console.log(response.data.products);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <Header />

      {/* SHOP-NOW START */}
      {/* <div className="shop-now"> */}
      <BannerWithoutImage
        firstSection={"Stay refreshed with Al Fajar Water!"}
        secondSection={
          "Shop now for pure hydration and support sustainable practices for a healthier planet."
        }
      />

      {/* BOTTLES START */}
      {products.length > 0 && (
        <div className="bottles bg-white pb-12 xs:py-8 lg:py-32">
          <div className="container mx-auto flex justify-evenly items-end space-y-10 lg:space-y-0 flex-wrap-on-small">
            {products.map((product) => (
              <ProductCard
                key={product._id}
                id={product._id}
                name={product.name}
                coverImage={product.coverImage}
                status={product.status}
                salePrice={product.salePrice}
              />
            ))}
          </div>
        </div>
      )}

      {/* BOTTLES ENDS */}
      {/* </div> */}
      {/* SHOP-NOW END */}

      <Footer />
    </div>
  );
};

export default ShopNow;
