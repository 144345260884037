import React from 'react';
import './style.css';

const BannerWithoutImage = ({firstSection, secondSection}) => {
  return (
    <div className="advertise-banner-wrapper w-full h-auto sm:h-60 flex justify-center items-center">
      <p className="banner-title uppercase text-center text-white text-3xl lg:text-6xl font-medium lg:font-semibold mb-2 lg:mb-4">
        {firstSection}
      </p>
      <p className="banner-description w-full max-w-full lg:max-w-screen-lg px-4 text-xl lg:text-[28px] font-light text-center text-white">
        {secondSection}
      </p>
    </div>
  );
};

export default BannerWithoutImage;
