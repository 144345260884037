import "./style.css";
import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import BannerWithoutImage from "../../components/BannerWithoutImage/BannerWithoutImage";
import gallon5 from "../../assets/images/gallon5.png";
import sportBottle from "../../assets/images/sportBottle.png";
import bottle from "../../assets/images/bottle.png";
import lighting from "../../assets/images/lighting.png";
import moneyTransfer from "../../assets/svg/money-transfer.svg";
import product from "../../assets/images/product.png";
import donationWave from "../../assets/images/donation-wave.png";
import icon1 from "../../assets/images/donate/donatise_03.png";
import icon2 from "../../assets/images/donate/donatise_06.png";
import icon3 from "../../assets/images/donate/donatise_10.png";
import icon5 from "../../assets/images/donate/donatise_37.png";
import icon6 from "../../assets/images/donate/donatise_39.png";
import icon7 from "../../assets/images/donate/donatise_42.png";
import icon8 from "../../assets/images/donate/donatise_45.png";

const Advertise = () => {
  useEffect(() => {
    // window.scrollTo(0, 0)
  });

  const miniBillboardBottlesArr = [
    {
      image: lighting,
      bottleSize: "200ML",
      price: "$47.50 (Case of 36)",
    },
    {
      image: bottle,
      bottleSize: "200ML",
      price: "$47.50 (Case of 36)",
    },
    {
      image: sportBottle,
      bottleSize: "200ML",
      price: "$47.50 (Case of 36)",
    },
    {
      image: gallon5,
      bottleSize: "200ML",
      price: "$47.50 (Case of 36)",
    },
  ];

  const miniBillboardPromosArr = [
    {
      svgImg: icon5,
      heading: "25x",
      desc: "more impressions than direct mail",
    },
    {
      svgImg: icon6,
      heading: "100PKR",
      desc: "cheaper per eight impressions than direct mail",
    },
    {
      svgImg: icon7,
      heading: "29%",
      desc: "return on investment",
    },
    {
      svgImg: icon8,
      heading: "Donations",
      desc: "Giving free water to those in need",
    },
  ];

  return (
    <div>
      <Header />

      {/* Advertise FORM START */}

      <div className="advertise">
        <BannerWithoutImage
          firstSection={"donate + advertise = donatise"}
          secondSection={
            "Advertise your brand and donate clean water to those in need, creating a meaningful impact while promoting your business."
          }
        />

        <div className="clean-slate sm:px-0 py-8 md:py-16">
          <div className="heading-text px-4 text-center">
            <h3 className="uppercase text-3xl lg:text-5xl font-normal mx-auto mb-2 lg:mb-3">
              Our product is like a clean slate
            </h3>
            <p className="w-full max-w-full lg:max-w-screen-xl text-lg lg:text-2xl font-normal mx-auto">
              We're not like regular ads that bother people. Instead of making
              them feel uncomfortable, we aim to make them happy. When people
              are happy, they're more likely to support your brand.
            </p>
          </div>
          <div className="container mx-auto px-2 flex flex-col lg:flex-row justify-center py-2 lg:py-4">
            <div className="img-div w-full lg:w-1/2 flex justify-center md:p-14">
              <img
                src={product}
                alt="theoryWaterbox1"
                className="w-full sm:w-1/5 md:flex-1 pb-2 sm:pb-0 md:h-auto"
              />
              {/* <img src={theoryWaterbox2} alt="theoryWaterbox2" className="w-1/5 md:flex-1 md:h-auto" />
                            <img src={theoryWaterbox3} alt="theoryWaterbox3" className="w-1/5 md:flex-1 md:h-auto" />
                            <img src={theoryWaterbox4} alt="theoryWaterbox4" className="w-1/5 md:flex-1 md:h-auto" /> */}
            </div>
            <div className="text-div w-full lg:w-1/2 flex flex-wrap px-1 sm:px-4 ld:px-0">
              <div className="w-1/2 flex justify-center items-center p-0.5 2xl:p-2">
                <div className="clean-slate-cards w-full h-full flex flex-col gap-2 py-4 2x:py-2 justify-center items-center bg-blue-100 rounded-lg">
                  <div className="img-div">
                    <img
                      src={icon1}
                      alt="smartPhone"
                      className="w-16 h-16 md:w-24 md:h-24"
                    />
                  </div>
                  <h5 className="text-xs sm:text-lg 2x:text-2xl font-semibold 2xl:font-bold text-[#37b6e8]">
                    Use QR Codes
                  </h5>
                  <p className="text-xs md:text-base font-light text-center w-11/12">
                    Use QR Codes to seamlessly link to online content such as
                    websites, coupons, and videos for enhanced accessibility and
                    engagement.
                  </p>
                </div>
              </div>
              <div className="w-1/2 flex justify-center items-center p-0.5 2xl:p-2">
                <div className="clean-slate-cards w-full h-full flex flex-col gap-2 py-4 2x:py-2 justify-center items-center bg-blue-100 rounded-lg">
                  <div className="img-div">
                    <img
                      src={icon2}
                      alt="box"
                      className="w-16 h-16 md:w-24 md:h-24"
                    />
                  </div>
                  <h5 className="text-xs sm:text-lg 2x:text-2xl font-semibold 2xl:font-bold text-[#37b6e8]">
                    Sell or Give Away
                  </h5>
                  <p className="text-xs md:text-base font-light text-center w-11/12">
                    Ad Supported Al Fajar Water in eco-friendly bottles,
                    offering sustainability with every refreshing sip.
                  </p>
                </div>
              </div>
              <div className="w-1/2 flex justify-center items-center p-0.5 2xl:p-2">
                <div className="clean-slate-cards w-full h-full flex flex-col gap-2 py-4 2x:py-2 justify-center items-center bg-blue-100 rounded-lg">
                  <div className="img-div">
                    <img
                      src={icon3}
                      alt="logistics"
                      className="w-16 h-16 md:w-24 md:h-24"
                    />
                  </div>
                  <h5 className="text-xs sm:text-lg 2x:text-2xl font-semibold 2xl:font-bold text-[#37b6e8]">
                    Pick A Distribution Method
                  </h5>
                  <p className="text-xs md:text-base font-light text-center w-11/12">
                    Whether you want it in any specific area in Lahore or
                    shipped across the city, choose a distribution method of
                    your own choice.
                  </p>
                </div>
              </div>
              <div className="w-1/2 flex justify-center items-center p-0.5 2xl:p-2">
                <div className="clean-slate-cards w-full h-full flex flex-col gap-2 py-4 2x:py-2 justify-center items-center bg-blue-100 rounded-lg">
                  <div className="img-div">
                    <img
                      src={moneyTransfer}
                      alt="moneyTransfer"
                      className="w-16 h-16 md:w-24 md:h-24"
                    />
                  </div>
                  <h5 className="text-xs sm:text-lg 2x:text-2xl font-semibold 2xl:font-bold text-[#37b6e8]">
                    Subsidize with Us
                  </h5>
                  <p className="text-xs md:text-base font-light text-center w-11/12">
                    Offset expenses by allocating ad space to non-competing
                    brands, either through subsidies or full coverage of costs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mini-bilboard bg-blue-100 pt-8 md:pt-16">
          <div className="heading-text px-3 sm:px-0 text-center">
            <h3 className="uppercase text-3xl lg:text-5xl font-normal mx-auto mb-2 lg:mb-3">
              Just Like a Mini Billboard in Your Hand
            </h3>
            <p className="w-full max-w-full lg:max-w-screen-xl text-lg lg:text-2xl font-normal mx-auto">
              A bottle design, it's like having a mini billboard right in the
              palm of your hand.
            </p>
          </div>

          {/* BOTTLES START */}

          <div className="bottles pt-4 pb-10 lg:pt-8 lg:pb-20">
            <div className="container mx-auto flex justify-evenly items-end flex-wrap gap-y-6 md:gap-y-0">
              {miniBillboardBottlesArr?.map(
                ({ image, bottleSize, price }, index) => {
                  return (
                    <div
                      key={index}
                      className="bottle-card flex flex-col justify-center items-center"
                    >
                      <a
                        href={`/item?pic=${encodeURIComponent(image)}`}
                        className="bottle-img-outer bottle-img-200-ml"
                      >
                        <img src={image} alt="water-200-ml" />
                      </a>
                      <p className="uppercase">{bottleSize}</p>
                      <p>{price}</p>
                      <button type="button" className="outline-none">
                        <span>Add To Cart</span>
                      </button>
                    </div>
                  );
                }
              )}
            </div>
          </div>

          {/* BOTTLES ENDS */}

          <div className="bilboard-promos relative">
            <div className="absolute -top-6 md:-top-12 xl:-top-20 left-0 right-0">
              <img src={donationWave} alt="donationWave" className="w-full" />
            </div>
            <div className="container relative z-10 mx-auto flex flex-wrap justify-center lg:justify-between items-stretch gap-y-3 lg:gap-y-0 px-1.5 lg:px-0 pb-6 pt-6 md:pb-12 2xl:pt-18">
              {miniBillboardPromosArr?.map(
                ({ svgImg, heading, desc }, index) => {
                  return (
                    <div
                      key={index}
                      className="flex flex-col gap-2 items-center lg:gap-4 w-1/2 lg:w-1/4 text-white"
                    >
                      <div className="img-div">
                        <img
                          src={svgImg}
                          alt="svgImg"
                          className="w-16 h-16 md:w-24 md:h-24"
                        />
                      </div>
                      <p className="text-3xl font-extrabold">{heading}</p>
                      <p className="text-lg w-full md:w-2/3 2xl:w-3/6 font-light text-center">
                        {desc}
                      </p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Advertise FORM ENDS */}

      <Footer />
    </div>
  );
};

export default Advertise;
