// src/components/ProductCard/ProductCard.js
import React from "react";
import PropTypes from "prop-types";

const ProductCard = ({ id, name, coverImage, status, salePrice }) => {
  return (
    <div className="w-1/2 xs:w-auto bottle-card flex flex-col justify-center items-center">
      {status ? (
        <a
          href={`/item/${id}`}
          className="bottle-img-outer bottle-img-5-Gallon"
        >
          <img src={coverImage} alt={name} />
        </a>
      ) : (
        <div className="bottle-img-outer bottle-img-5-Gallon">
          <img src={coverImage} alt={name} />
        </div>
      )}
      <p>{name}</p>
      {salePrice === 0 ? <div className="mt-10"></div> : <p>Rs. {salePrice}</p>}
      <div className="outline-none mt-2">
        {status ? (
          <a href={`/item/${id}`} className="outline-none">
            <span>Add To Cart</span>
          </a>
        ) : (
          <span>Coming Soon</span>
        )}
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  coverImage: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  salePrice: PropTypes.number.isRequired,
};

export default ProductCard;
