import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./style.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />

      <div className="privacy-policy">
        <div className="contact-banner b w-full h-40 flex justify-center items-center">
          <p className="uppercase text-center text-white text-5xl font-extrabold">
            privacy policy
          </p>
        </div>

        <div className="container mx-auto py-4 md:py-8 my-5 md:my-16">
          <div className="text-div w-full px-2 lg:px-8 flex flex-col gap-4">
            <div className="text">
              <p>
                At Al Fajar Water, we are committed to protecting your privacy
                and ensuring the security of your personal information. This
                Privacy Policy outlines how we collect, use, and safeguard your
                data when you interact with our website, products, or services.
                By using our website or purchasing our products, you agree to
                the terms of this Privacy Policy.
              </p>
            </div>

            <div className="text">
              <span className="heading block">Information We Collect</span>
              <p>
                We collect personal information that you provide to us
                voluntarily, such as your name, address, email address, phone
                number, and payment details when you make a purchase. We may
                also collect non-personal information automatically, including
                your IP address, browser type, and browsing behavior on our
                website.
              </p>
            </div>

            <div className="text">
              <span className="heading block">How We Use Your Information</span>
              <p>
                We use your personal information to process your orders,
                communicate with you about your purchases, and provide customer
                support. We may also use your contact information to send you
                marketing communications about our products and promotions, but
                you can opt out of these communications at any time.
              </p>
            </div>

            <div className="text">
              <span className="heading block">Sharing Your Information</span>
              <p>
                We do not sell, trade, or rent your personal information to
                third parties for their marketing purposes. However, we may
                share your information with trusted third-party service
                providers who assist us in operating our website, processing
                payments, or delivering products to you. These service providers
                are contractually obligated to keep your information
                confidential and use it only for the purposes of providing
                services to us.
              </p>
            </div>

            <div className="text">
              <span className="heading block">Data Security</span>
              <p>
                We implement a variety of security measures to protect your
                personal information from unauthorized access, misuse, or
                alteration. These measures include encryption, firewalls, and
                regular security audits. However, no method of transmission over
                the internet or electronic storage is 100% secure, so we cannot
                guarantee absolute security.
              </p>
            </div>

            <div className="text">
              <span className="heading block">Cookies</span>
              <p>
                We use cookies and similar tracking technologies to enhance your
                browsing experience, analyze website traffic, and personalize
                content and advertisements. You can control cookies through your
                browser settings and opt out of targeted advertising by
                adjusting your preferences in our Cookie Policy.
              </p>
            </div>

            <div className="text">
              <span className="heading block">Children's Privacy</span>
              <p>
                Our website and products are not intended for children under the
                age of 13, and we do not knowingly collect personal information
                from children. If you are a parent or guardian and believe that
                your child has provided us with personal information, please
                contact us immediately, and we will take steps to delete such
                information.
              </p>
            </div>

            <div className="text">
              <span className="heading block">
                Changes to this Privacy Policy
              </span>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or applicable laws. We will notify you
                of any material changes by posting the updated Privacy Policy on
                our website. Your continued use of our website or products after
                the posting of changes constitutes your acceptance of those
                changes.
              </p>
            </div>

            <div className="text">
              <span className="heading block">Contact Us</span>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at
                info@alfajarwater.com. We are committed to addressing your
                inquiries promptly and transparently.
              </p>
            </div>

            <div className="text">
              <p>
                Your privacy is important to us, and we are dedicated to
                protecting the confidentiality and security of your personal
                information.By using our website or purchasing our products, you
                trust us with your data, and we take that responsibility
                seriously.We will continue to strive for transparency,
                accountability, and compliance with privacy laws to maintain
                your trust and confidence in Al Fajar Water.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
