import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Index from './pages/index/Index';
// import Media from './pages/media/Media';
import Water from './pages/our-water/Water';
import Contact from './pages/contact-us/Contact';
import Mission from './pages/our-mission/Mission';
import Map from './pages/map/Map';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import ShopNow from './pages/shop-now/ShopNow';
import ItemDetail from './pages/item-detail/ItemDetail';
import Accessibility from './pages/accessibility/Accessibility';
import FAQ from './pages/faqs/FAQ';
import TermsAndCondition from './pages/terms-and-condition/TermsAndCondition';
import RefundPolicy from './pages/refund-policy/RefundPolicy';
import Advertise from './pages/advertise/Advertise';
import MediaDetail from './pages/media-detail/MediaDetail';
import CheckoutPage from './pages/checkout-final/CheckoutPage';


const IndexRoutes = () => {

  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/home" element={<Index />} />
      {/* <Route path="/media-pr" element={<Media />} /> */}
      <Route path="/media-detail" element={<MediaDetail />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/our-water" element={<Water />} />
      <Route path="/our-mission" element={<Mission />} />
      <Route path="/find-a-store" element={<Map />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-condition" element={<TermsAndCondition />} />
      <Route path="/refund-policy" element={<RefundPolicy />} />
      <Route path="/accessibility" element={<Accessibility />} />
      <Route path="/shop-now" element={<ShopNow />} />
      <Route path="/item/:id" element={<ItemDetail />} />
      <Route path="/faqs" element={<FAQ />} />
      <Route path="/donatise" element={<Advertise />} />
      <Route path="/checkout" element={<CheckoutPage />} />

    </Routes>
  )
}

export default IndexRoutes
