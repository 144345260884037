import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./style.css";

const arrowRight = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      fill="#00afef"
      d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z"
    />
  </svg>
);

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const faqsList = [
    {
      question: "Is Al Fajar Water purified?",
      answer:
        "Yes, Al Fajar Water undergoes meticulous purification processes to guarantee its purity and quality. We utilize advanced filtration techniques to remove impurities and contaminants, ensuring that our water meets the highest standards of cleanliness and safety. With our commitment to excellence, you can trust that every sip of Al Fajar Water is refreshingly pure and wholesome.",
    },
    {
      question: "What makes Al Fajar Water different from other brands?",
      answer:
        "Al Fajar Water distinguishes itself through its exceptional quality, sourced from protected underground sources. Our commitment to community empowerment sets us apart, as every purchase supports our mission to provide clean water to those in need. With a focus on purity, sustainability, and social responsibility, Al Fajar Water stands as a beacon of integrity in the bottled water industry.",
    },
    {
      question: "Are there different sizes available?",
      answer:
        "Al Fajar Water offers a variety of sizes to accommodate diverse preferences and needs. Our range includes convenient 200 ml bottles, 300 ml bottles, ideal for on-the-go hydration, as well as 1.5 Liter bottles and larger containers 19 Liters for households and gatherings. Whether you prefer a compact bottle for personal use or a bulk option for shared consumption, we have sizes to suit every lifestyle.",
    },
    {
      question: "Is Al Fajar Water environmentally friendly?",
      answer:
        "Al Fajar Water aims for environmental friendliness through sustainable practices like eco-friendly packaging and responsible sourcing. However, thorough research into their specific environmental impact would provide a clearer answer.",
    },
    {
      question: "Can I purchase Al Fajar Water online?",
      answer:
        "Yes, you can buy Al Fajar Water online through various platforms like our official website or our app. ",
    },
    {
      question: "Does Al Fajar Water contain any additives?",
      answer:
        "Al Fajar Water typically contains no additives, offering pure and natural hydration. However, it's advisable to check the product label or contact the manufacturer directly for specific ingredient information.",
    },
    {
      question: "Is Al Fajar Water safe for children to drink?",
      answer:
        "Yes, Al Fajar Water is generally safe for children to drink. It undergoes stringent quality control measures to ensure purity and safety. However, parental discretion is advised, especially for infants and young children, considering individual sensitivities and health conditions.",
    },
    {
      question: "How does Al Fajar Water support communities?",
      answer:
        "Al Fajar Water supports communities by implementing various social responsibility initiatives. They may engage in projects like providing clean drinking water to underserved areas. ",
    },
    {
      question: "Can I find Al Fajar Water in stores near me?",
      answer: "Yes. To locate the stores near you, click here. ",
    },
  ];

  return (
    <div>
      <Header />
      <div className="faqs">
        <div className="faq-banner b w-full h-40 flex justify-center items-center">
          <p className="text-center text-white text-5xl font-extrabold">
            FAQ's
          </p>
        </div>

        <div className="container 2xl:mx-auto my-5 md:my-16">
          <div className="w-full flex flex-col sm:flex-row justify-center px-4 2xl:justify-between items-start">
            <div className="min-w-fit w-full sm:w-1/12 mb-6 lg:mb-0">
              <p className="text-heading heading-top text-center md:text-start text-9xl md:text-[170px] font-extralight uppercase">
                Q
              </p>
            </div>
            <div className="w-full sm:w-10/12 md:px-8 lg:px-14">
              {faqsList?.map(({ question }, index) => {
                return (
                  <a
                    href={`#q${index}`}
                    key={index}
                    className="flex gap-2 items-start lg:items-center text-heading mb-4 sm:mb-5 lg:mb-6"
                  >
                    <span className="arrow w-3 h-3 sm:w-5 sm:h-5 mt-2 sm:mt-1">
                      {arrowRight}
                    </span>
                    <p className="question text-lg sm:text-xl font-normal flex-1">
                      {question}
                    </p>
                  </a>
                );
              })}
            </div>
          </div>
        </div>

        <div className="question-answers 2xl:container 2xl:mx-auto my-3 md:my-12">
          {faqsList.map(({ question, answer }, index) => {
            return (
              <div
                key={index}
                id={`q${index}`}
                className="faqas-outer w-full xl:min-h-40 h-auto py-4 px-5 lg:px-7 lg:py-6 flex flex-col sm:flex-row justify-center 2xl:justify-start items-start my-4 lg:my-6 text-justify md:text-start"
              >
                <div className="min-w-fit w-full sm:w-1/12 mb-4 lg:mb-0">
                  <p className="text-heading text-center md:text-start text-5xl md:text-8xl font-extralight uppercase">
                    Q
                  </p>
                </div>
                <div className="w-full sm:w-11/12 md:px-4 lg:px-8">
                  <p className="question text-lg sm:text-2xl font-normal flex-1 mb-1 md:mb-2">
                    {question}
                  </p>
                  <p className="answer">{answer}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="container mx-auto flex flex-col lg:flex-row gap-4 md:gap-12 md:justify-center items-center my-20">
          <p className="text-2xl text-center lg:text-start md:text-3xl font-light opacity-65">
            Didn't find an answer to your question?
          </p>
          <button
            type="button"
            className="w-40 h-10 md:w-52 md:h-12 rounded-md bg-black"
          >
            <span className="text-lg md:text-xl text-white font-semibold">
              Click to talk
            </span>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
