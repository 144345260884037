import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import bottleImage from "../../assets/svg/bottles-icon-svg.svg";
import calendarImage from "../../assets/svg/calendar-icon-svg.svg";
import boxImage from "../../assets/svg/box-icon-svg.svg";
import ourMission1 from "../../assets/images/our-mission.jpg";
import ourWater from "../../assets/images/our-water.jpg";

import Banner1 from "../../assets/images/banner1-updated.jpeg";
import Banner2 from "../../assets/images/banner2.jpg";
import Banner3 from "../../assets/images/banner3.jpg";
import gallons from "../../assets/images/3-gallons.png";
import ice from "../../assets/svg/ice.svg";
import waterComposition from "../../assets/images/water-composition.png";

import "./style.css";
import axios from "axios";
import { BASE_URL } from "../../utils/config";
import ProductCard from "../../components/ProductCard/ProductsCard";

const Index = () => {
  const [instagramImages, setInstagramImages] = useState([]);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getProducts();
    getInstagramImages();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}product/get_all_products`);
      if (response.status === 200) {
        setProducts(response.data.products);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getInstagramImages = async () => {
    await axios
      .get(`${BASE_URL}promotion/get_all_promotions?promotionType=Instagram`)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data.promotions);
          setInstagramImages(response.data.promotions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const carouselItems = [
    {
      image: Banner1,
      heading: "Number #1 Mineral Water in Pakistan",
      text: `We proudly claim the top spot in Pakistan's mineral water market, backed by concrete evidence validating our superior quality and standards.`,
      button: "Learn More",
    },
    {
      image: Banner2,
      heading: "Purest and Finest Mineral Water",
      text: "Al Fajar Water offers the perfect blend of vital minerals, essential for a balanced and healthy lifestyle.",
      button: "Learn More",
    },
    {
      image: Banner3,
      heading: "You Drink, You Donate!",
      text: "With every sip, you support our mission. Every bottle sold contributes to vital initiatives, making a difference with every bottle.",
      button: "Learn More",
    },
  ];

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="black"
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"
            />
          </svg>
        </span>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span>
          <svg
            className="rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="black"
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"
            />
          </svg>
        </span>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const instaSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClickMission = () => {
    navigate("/donatise");
  };
  const handleClickOurWater = () => {
    navigate("/our-water");
  };

  return (
    <div>
      <Header />
      {/* BANNER START */}
      <div className="banner w-full flex justify-center items-center px-4 md:px-12 xl:px-4">
        <div className="container relative mx-auto home-banner-carousel">
          <Slider {...settings}>
            {carouselItems.map((item, index) => {
              const isActive = index === 0;
              return (
                <div
                  key={index}
                  className={`carousel-item ${isActive ? "active" : ""}`}
                >
                  <div className="home-banner-carousel-item relative flex flex-col lg:flex-row items-stretch justify-center z-10">
                    {/* Text Section */}
                    <div className="text-div px-6 sm:px-0 lg:w-1/2 flex flex-col justify-center text-center lg:text-start items-center lg:items-start mb-6 md:mb-0">
                      <h1 className="">{item.heading}</h1>
                      <p className="mt-2 mb-6">{item.text}</p>
                      <button type="button" className="bg-black learn-more">
                        {item.button}
                      </button>
                    </div>

                    {/* Image Section */}
                    <div className="image-div w-full mx-auto lg:w-1/2 banner-img">
                      <img
                        src={item.image}
                        alt="Banner"
                        className="w-full mx-auto md:rounded-lg"
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      {/* BANNER ENDS */}

      {/* QUICK & EASY START */}
      <div className="quick-easy bg-black py-32">
        <div className="container mx-auto flex flex-col justify-center items-center w-full">
          <div className="heading flex flex-col justify-center items-center w-full text-center">
            <span>Subscribe To Save</span>
            <span>Our Commitment</span>
          </div>
          <div className="cards-div w-full my-6 sm:mb-0 sm:mt-12 flex flex-col sm:flex-row justify-evenly items-center gap-8 sm:gap-0">
            <div className="card">
              <span className="flex justify-center w-80 h-60 sm:w-60 sm:h-32">
                <img src={bottleImage} alt="bottle" className="w-full h-full" />
              </span>
              <p>Premium Quality</p>
              <p>
                We adhere to the highest standards of quality control to ensure
                that every bottle of Al Fajar mineral water is pristine and
                pure.
              </p>
            </div>
            <div className="card">
              <span className="flex justify-center w-80 h-60 sm:w-60 sm:h-32">
                <img
                  src={calendarImage}
                  alt="calendar"
                  className="w-full h-full"
                />
              </span>
              <p>Natural Essence</p>
              <p>
                Our water is sourced from protected underground sources,
                untouched by pollutants, and infused with essential minerals for
                your well-being.
              </p>
            </div>
            <div className="card">
              <span className="flex justify-center w-80 h-60 sm:w-60 sm:h-32">
                <img src={boxImage} alt="botel" className="w-full h-full" />
              </span>
              <p>Sustainable Practices</p>
              <p>
                We are dedicated to sustainable water management practices,
                preserving the environment for future generations.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* QUICK & EASY END */}

      {/* BOTTLES START */}

      {products.length > 0 && (
        <div className="bottles bg-white pb-12 xs:py-8 lg:py-32">
          <div className="container mx-auto flex justify-evenly items-end space-y-10 lg:space-y-0 flex-wrap-on-small">
            {products.map((product) => (
              <ProductCard
                key={product._id}
                id={product._id}
                name={product.name}
                coverImage={product.coverImage}
                status={product.status}
                salePrice={product.salePrice}
              />
            ))}
          </div>
        </div>
      )}

      {/* {products.length > 0 && (
        <div className="bottles bg-white pt-12 pb-16 lg:pb-20">
          {products.map((product) => (
            <div
              key={product._id}
              className="bottle-card flex-col justify-center items-center"
            >
              {product.status ? (
                <a
                  href={`/item/${product._id}`}
                  className="bottle-img-5-Gallon"
                >
                  <img src={product.coverImage} alt={product.name} />
                </a>
              ) : (
                <div className="bottle-img-5-Gallon">
                  <img src={product.coverImage} alt={product.name} />
                </div>
              )}
              <p>{product.name}</p>
              {product.salePrice === 0 ? (
                <div className="mt-10"></div>
              ) : (
                <p>Rs. {product.salePrice}</p>
              )}
              <div className="outline-none">
                {product.status ? (
                  <a href={`/item/${product._id}`} className="outline-none">
                    <span>Add To Cart</span>
                  </a>
                ) : (
                  <span>Coming Soon</span>
                )}
              </div>
            </div>
          ))}
        </div>
      )} */}

      {/* BOTTLES ENDS */}

      {/* OUR-MISSION START */}

      <div className="our-mission">
        <div className="div-1 flex flex-col md:flex-row pb-8 sm:pb-0">
          <div className="img-div w-full sm:w-1/2">
            <img
              src={ourMission1}
              alt=""
              className="object-cover object-top h-full w-full"
            />
          </div>
          <div className="txt-div w-1/2 flex flex-col justify-center ps-8 lg:pe-0 px-8">
            <p>Donatise &nbsp;</p>
            <h3>Advertise with Us</h3>
            <span>
              Choose the innovative advertising solution that captivates
              audiences, boosting brand visibility and leveraging the
              advertising industry's most potent platform for enhanced
              engagement and influence. Share space with similar brands, saving
              money while still getting noticed. Great for budget-friendly
              marketing plans.
            </span>
            <button
              type="button"
              className="outline-none"
              onClick={handleClickMission}
            >
              <span>Learn More</span>
            </button>
          </div>
        </div>

        <div className="div-2 flex flex-col md:flex-row-reverse justify-start sm:justify-between pb-8 sm:pb-0">
          <div className="img-div w-full sm:w-1/2">
            <img
              src={ourWater}
              alt=""
              className="object-cover object-top h-full w-full"
            />
          </div>
          <div className="txt-div w-1/2 flex flex-col justify-center lg:ml-6 xl:ml-24 ps-8 lg:pe-0 px-8">
            <p className="text-white">Our Water &nbsp;</p>
            <h3 className="text-white ">
              Experience The Purity and Freshness of Al Fajar
            </h3>
            <ul className="custom-list text-white">
              <li>
                <strong>Trusted Source:</strong> With years of expertise and a
                commitment to excellence, Al Fajar is the trusted choice for
                discerning consumers.
              </li>
              <li>
                <strong>Health and Wellness:</strong> Our mineral-rich water
                promotes hydration and vitality, supporting your overall health
                and well-being.
              </li>
              <li>
                <strong>Unmatched Taste:</strong> From the geologically and
                physically protected underground source to your table, Al Fajar
                delivers an unparalleled taste experience that you'll love.
              </li>
            </ul>
            <button
              type="button"
              className="outline-none"
              onClick={handleClickOurWater}
            >
              <span>Read More</span>
            </button>
          </div>
        </div>
      </div>

      {/* OUR-MISSION ENDS */}

      {/* Uaques-Basic-Water START */}

      <div className="uaques-basic-water">
        <div className="relative container flex justify-center items-center mx-auto">
          <div className="rings flex justify-center items-center">
            <p className="no-min-orders text-white text-xs md:text-base w-1/2 text-center md:w-full">
              No Minimum Orders
            </p>
          </div>
          <div className="bottles">
            <img src={gallons} alt="" />
          </div>
          <div className="ice-cubes">
            <img src={ice} alt="ics" />
          </div>
        </div>
        <div className="composition-div-outer w-full">
          <div className="container mx-auto flex flex-col justify-center items-center">
            <h1 className="heading">Al Fajar Water Composition</h1>
            <div className="composition-div flex justify-stretch items-stretch">
              <div className="composition-txt div-1">
                <div className="compo-elemnt">
                  <h3>
                    <span className="block">Calcium</span>{" "}
                    <span>40 - 70 mg/L</span>{" "}
                  </h3>
                  <p>
                    Fortify bones, promote growth, essential mineral for health.
                  </p>
                </div>

                <div className="compo-elemnt">
                  <h3>
                    <span className="block">Magnesium</span>{" "}
                    <span>4 - 15 mg/L</span>{" "}
                  </h3>
                  <p>
                    Nourish muscles, regulate heartbeat, vital for body
                    functions.
                  </p>
                </div>
              </div>
              <div className="glass-img">
                <img src={waterComposition} alt="water-composition" />
              </div>
              <div className="composition-txt div-2">
                <div className="compo-elemnt">
                  <h3>
                    <span className="block">Potassium</span>{" "}
                    <span>0.1 - 2 mg/L</span>{" "}
                  </h3>
                  <p>
                    Balance fluids, support nerve signals, crucial for heart
                    health.
                  </p>
                </div>

                <div className="compo-elemnt">
                  <h3>
                    <span className="block">Sodium</span>{" "}
                    <span>10 - 20 mg/L</span>{" "}
                  </h3>
                  <p>
                    Regulate blood pressure, aid nerve function, essential
                    electrolyte.
                  </p>
                </div>
              </div>
            </div>
            <div className="compo-last-div w-full md:w-2/3 mt-3 md:mt-6 flex justify-evenly items-center">
              <h1 className="text-center uppercase font-bold">
                <span className="block mb-1 md:mb-3">Fluoride</span>
                <span className="block">0 - 0.1 MG/L</span>
              </h1>
              <h1 className="text-center uppercase font-bold">
                <span className="block mb-1 md:mb-3">Nitrate</span>
                <span className="block">0 - 0.1 MG/L</span>
              </h1>
              <h1 className="text-center uppercase font-bold">
                <span className="block mb-1 md:mb-3">Chloride</span>
                <span className="block">77 - 150 MG/L</span>
              </h1>
              <h1 className="text-center uppercase font-bold">
                <span className="block mb-1 md:mb-3">Sulphate</span>
                <span className="block">12 - 50 MG/L</span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      {/* Uaques-Basic-Water ENDS */}

      {/* INSTA START */}

      <div className="insta w-full my-6">
        <div className="heading w-full text-center">
          <p className="uppercase text-xl font-bold">On The Gram</p>
          <p className="font-light w-full md:w-3/4 xl:w-2/4 2xl:w-1/4 px-2 mx-auto">
            Peek into our Instagram gallery to know more about us.
          </p>
        </div>
      </div>
      <div className="insta-carousel w-full my-5">
        {instagramImages.length > 0 ? (
          <Slider {...instaSettings}>
            {instagramImages.map((image, index) => (
              <div className="img-card relative" key={index}>
                <a
                  href="https://www.instagram.com/alfajarwaters/"
                  target="_blank"
                >
                  <img src={image.image} alt={`img-${index}`} />
                </a>
                <div className="txt-div absolute top-0 left-0 right-0 bottom-0">
                  <div className="w-full h-full flex items-end px-5 py-2"></div>
                </div>
              </div>
            ))}
          </Slider>
        ) : (
          <p>Loading...</p>
        )}
      </div>
      {/* INSTAGRAM FEED END */}

      <Footer />
    </div>
  );
};

export default Index;
