import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import wavy_bg from "../..//assets/images/wavy-bg.png";
import zw_5in1 from "../../assets/images/mission/Our-Mission_13.jpg";
import GlobalVillage from "../../assets/images/mission/Our-Mission_05.jpg";
import PreventPollution from "../../assets/images/mission/Our-Mission_09.jpg";
import MissionLogo1 from "../../assets/images/mission/Our-Mission_20.jpg";
import MissionLogo2 from "../../assets/images/mission/Our-Mission_22.jpg";
import MissionLogo3 from "../../assets/images/mission/Our-Mission_24.jpg";
import OurMission1 from "../../assets/images/mission/Our-Mission_16.jpg";

import "./style.css";

const Mission = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />
      <div className="our-mission">
        {/* BANNER START */}

        <div className="mission-banner w-full relative flex justify-center">
          <div className="text-div px-3 sm:px-0 lg:max-w-screen-lg lg:px-4 relative z-10 text-center flex flex-col justify-center items-center gap-4 text-white">
            <h2 className="uppercase text-3xl xs:text:4xl sm:text-4xl md:text-6xl xl:text-[5rem] font-semibold sm:font-bold lg:font-extrabold">
              WE ARE ON A MISSION
            </h2>
            <p className="uppercase text-lg md:text-xl lg:text-3xl xl:text-[2.5rem] lg:font-semibold px-2 sm:px-8 lg:px-4">
              At Al Fajar Water, we aim to do more than just provide great
              mineral water. Our goal is to help communities with a simple idea:
              "You Drink, You Donate."
            </p>
            <span className="uppercase text-xl md:text-2xl font-light">
              find out how
            </span>
          </div>
        </div>
        <div className="bar-image"></div>
        {/* BANNER ENDS */}

        {/* GOALS START */}

        <div className="goals-div w-full py-10 md:py-20 bg-[#3bc7f3]">
          <div className="heading flex flex-col justify-center items-center">
            <h4 className="uppercase">Drink Al Fajar Water And Provide</h4>
            <h1 className="uppercase"> Clean Water </h1>
            <h5 className="uppercase">
              To Communities in Need with Every Sip!
            </h5>
            <img src={wavy_bg} alt="wavy_bg" className="my-6" />
          </div>

          <div className="goals-items flex flex-col justify-center items-center gap-8 sm:gap-2">
            <div className="goal-item flex flex-col-reverse sm:flex-row justify-evenly sm:justify-between items-center">
              <div className="text-div max-w-lg w-4/5 sm:w-1/2 text-center">
                <p className="heading">Choose Al Fajar Water.</p>
                <p className="desc">
                  Select your favorite Al Fajar water bottle from our range of
                  pure quality options.
                </p>
                <button
                  type="button"
                  onClick={() => {
                    navigate("shop-now");
                  }}
                >
                  <span>Buy and Help</span>
                </button>
              </div>
              <div className="img-div max-w-lg w-3/5 sm:w-1/2">
                <span>
                  <img src={GlobalVillage} alt="bottels-gif" />
                </span>
              </div>
            </div>

            <div className="goal-item flex flex-col-reverse sm:flex-row-reverse justify-evenly sm:justify-between items-center">
              <div className="text-div max-w-lg w-4/5 sm:w-1/2 text-center">
                <p className="heading">Enjoy Hydration.</p>
                <p className="desc">
                  Drink our refreshing water, knowing each sip contributes to
                  our mission of providing clean water to communities.
                </p>
                <button
                  type="button"
                  onClick={() => {
                    navigate("shop-now");
                  }}
                >
                  <span>Buy and Help</span>
                </button>
              </div>
              <div className="img-div max-w-lg w-3/5 sm:w-1/2">
                <span>
                  <img src={PreventPollution} alt="bottels-gif" />
                </span>
              </div>
            </div>

            <div className="goal-item flex flex-col-reverse sm:flex-row justify-evenly sm:justify-between items-center">
              <div className="text-div max-w-lg w-4/5 sm:w-1/2 text-center">
                <p className="heading">Make a Difference.</p>
                <p className="desc">
                  With every bottle you drink, you're helping us donate water to
                  those in need. Together, we inspire change.
                </p>
                <button
                  type="button"
                  onClick={() => {
                    navigate("shop-now");
                  }}
                >
                  <span>Buy and Help</span>
                </button>
              </div>
              <div className="img-div max-w-lg w-3/5 sm:w-1/2">
                <span>
                  <img src={zw_5in1} alt="bottels-gif" />
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* GOALS ENDS */}

        {/* OUR-MISSION START */}

        <div className="our-mission">
          <div className="div-1 flex flex-wrap">
            <div className="img-div w-full sm:w-1/2">
              <img
                src={OurMission1}
                alt="Image"
                className="object-cover object-top h-full w-full"
              />
            </div>
            <div className="txt-div w-full sm:w-1/2 flex flex-col justify-center ps-8 pb-6 sm:pb-0">
              <p>FULFILLING OUR MISSION &nbsp;</p>
              <h3>Empowering Through Pure Quality Water</h3>
              <span>
                At Al Fajar, our mission extends beyond providing exceptional
                mineral water. We're dedicated to empowering communities through
                a simple yet impactful concept: "You Drink, You Donate."
              </span>
              <span>
                At the heart of our mission lies our commitment to delivering
                pure, high-quality water to our consumers. We ensure that every
                bottle of Al Fajar water is sourced from protected underground
                sources, undergoes rigorous filtration processes, and meets the
                highest standards of purity and freshness.
              </span>
              <span>
                We believe that access to clean water is a fundamental human
                right, and we strive to make it accessible to all.
              </span>
              <button
                type="button"
                className="outline-none"
                onClick={() => {
                  navigate("/our-water");
                }}
              >
                <span>Read More</span>
              </button>
            </div>
          </div>
        </div>

        {/* OUR-MISSION ENDS */}

        {/* DONATES START */}
        <div className="donation w-full py-12 sm:my-12">
          <div className="container max-w-screen-lg mx-auto flex flex-col justify-center items-center text-center gap-4">
            <p className="heading text-xl md:text-3xl text-[#00c2ff] font-extrabold">
              Hello to A Brighter Future!
            </p>
            <p className="desc w-5/6 text-base md:text-lg font-light">
              Join us in our mission as we continue to champion the
              transformative power of pure quality water. Together, we can
              create a brighter future for generations to come.
            </p>
            <button
              type="button"
              className="uppercase rounded-full px-6 py-4 bg-[#93c5fd6e]"
            >
              <span className="text-xs font-extrabold text-black">
                Learn More
              </span>
            </button>

            <div className="logo-div flex justify-center items-center gap-6 sm:gap-2 md:gap-5 mt-4 md:mt-8">
              <img
                src={MissionLogo1}
                alt="mission-logo-1"
                className="sm:mx-4 sm:px-4"
              />
              <img
                src={MissionLogo2}
                alt="mission-logo-2"
                className="sm:mx-4 sm:px-4"
              />
              <img
                src={MissionLogo3}
                alt="mission-logo-3"
                className="sm:mx-4 sm:px-4"
              />
              {/* <img src={MissionLogo4} alt="mission-logo-4" />
              <img src={MissionLogo5} alt="mission-logo-5" /> */}
            </div>
          </div>
        </div>
        {/* DONATES ENDS */}
      </div>
      <Footer />
    </div>
  );
};

export default Mission;
