import React, { useEffect, useState } from 'react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import mountain from '../../assets/images/whats_your_true_nature_mountain.png';

import youngFemalevolunteers from "../../assets/images/young-female-volunteers.png";
import groupOfVolunteersWithWorkingInCommunity from "../../assets/images/group-of-volunteers-with-working-in-community.png";
import groupPeopleVolunteer from "../../assets/images/group-people-volunteer.png";
import groupOfMultiracialVolunteersWorking from "../../assets/images/group-of-multiracial-volunteers-working.png";
import groupOfMultiracialVolunteers from "../../assets/images/group-of-multiracial-volunteers.png";
import closeUpOfVolunteerTeamGathering from "../../assets/images/close-up-of-volunteer-team-gathering-hands.png";

import './style.css';

const mediaImagesArr = [
    {
        image: youngFemalevolunteers,
    },
    {
        image: groupOfVolunteersWithWorkingInCommunity,
    },
    {
        image: groupPeopleVolunteer,
    },
    {
        image: groupOfMultiracialVolunteersWorking,
    },
    {
        image: groupOfMultiracialVolunteers,
    },
    {
        image: closeUpOfVolunteerTeamGathering,
    },
];

const MediaDetail = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [selectedMediaImage, setSelectedMediaImage] = useState(mediaImagesArr[0]?.image);

    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#00afef" d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                </span>
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <div className={className} onClick={onClick}>
                <span>
                    <svg className='rotate-180' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#00afef" d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                </span>
            </div>
        );
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        mobileFirst: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
    };

    const handleClick = (e, image) => {
        e.preventDefault();
        e.stopPropagation();
        setSelectedMediaImage(image);
    };

    const mediaItem = {};

    return (
        <div className='media-detail'>
            <Header />

            {/* BANNER START */}

            <div className="media-banner w-full">
                <div className="media-banner-inner relative flex justify-center items-center overflow-hidden py-12 lg:py-24">
                    {/* <img src={mountain} alt="whats-your-nature" className="bg-img absolute top-0 bottom-0 left-0 right-0 w-full h-full object-cover" /> */}
                    <p className='relative container max-w-screen-xl uppercase text-2xl xl:text-5xl text-center leading-[inherit] font-extrabold text-white z-10'>The way the world  learns to dive.</p>
                </div>
            </div>

            {/* BANNER ENDS */}

            {/* MEDIA-ITEM START */}
            <div className="container relative mx-auto flex flex-col md:px-12 lg:px-2 space-y-2 md:space-y-8 pt-10 md:pt-16 pb-6 md:pb-10">
                <div className="photo-video-div max-w-screen-lg mx-auto relative w-full h-40 sm:h-60 md:h-96 xl:h-[600px]">
                    <div className="absolute top-0 bottom-0 left-0 right-0">
                        <div className="video-div hidden w-full">

                        </div>
                        <div className="image-div w-4/5 mx-auto xl:w-full h-full">
                            <img src={selectedMediaImage && selectedMediaImage} alt="selectedMediaImage" className='w-full h-full object-contain rounded-md' />
                        </div>
                    </div>
                    <div className="absolute -top-10 left-0 right-0 lg:top-0 lg:left-8 lg:right-3/4 xl:-left-32 xl:right-[98%]">
                        <div className="flex flex-row justify-evenly lg:flex-col gap-1.5">
                            <button type="button" className="relative px-4 py-2 rounded-md shadow-lg border-2 active">
                                <span className="text-xl font-bold uppercase">
                                    photos
                                </span>
                            </button>
                            <button type="button" className="relative px-4 py-2 rounded-md shadow-lg border-2">
                                <span className="text-xl font-bold uppercase">
                                    videos
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="media-carousel-images max-w-full lg:max-w-screen-lg mx-auto px-2 md:px-24">
                    <Slider {...settings}>
                        {mediaImagesArr?.map(({ image }, index) => {
                            return (
                                <div key={index}
                                    onClick={(e) => handleClick(e, image)}
                                    className="w-auto h-auto sm:w-16 md:w-32 rounded-md px-2 py-1.5">
                                    <img src={image} alt="image" className='w-full h-full object-contain rounded-lg overflow-hidden' />
                                </div>
                            );
                        })}
                    </Slider>
                </div>

            </div>
            {/* MEDIA-ITEM ENDS */}

            <div className="description max-w-screen-lg mx-auto px-6 xl:px-24 text-center pb-10">
                <h1 className="text-2xl md:text-4xl font-semibold uppercase">
                    the way the world learns to dive.
                </h1>
                <p className="font-light mt-2.5 text-justify">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  industry's standard dummy text ever since the 1500s, when an unknown printer Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="font-light mt-4 text-justify">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  industry's standard dummy text ever since the 1500s, when an unknown printer Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                </p>
            </div>

            <Footer />
        </div>
    )
}

export default MediaDetail