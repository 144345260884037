import React from "react";
import { BrowserRouter } from "react-router-dom";
import IndexRoutes from "./routes";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <IndexRoutes />
    </BrowserRouter>
  );
}

export default App;
