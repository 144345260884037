import "./style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useState, useEffect } from "react";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField } from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import axios from "axios";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Checkout from "../../components/Checkout/Checkout";
import { BASE_URL } from "../../utils/config";

const emptyStar = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="#00afef"
      d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
    />
  </svg>
);
const halfStar = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="#00afef"
      d="M309.5 13.5C305.5 5.2 297.1 0 287.9 0s-17.6 5.2-21.6 13.5L197.7 154.8 44.5 177.5c-9 1.3-16.5 7.6-19.3 16.3s-.5 18.1 5.9 24.5L142.2 328.4 116 483.9c-1.5 9 2.2 18.1 9.7 23.5s17.3 6 25.3 1.7l137-73.2 137 73.2c8.1 4.3 17.9 3.7 25.3-1.7s11.2-14.5 9.7-23.5L433.6 328.4 544.8 218.2c6.5-6.4 8.7-15.9 5.9-24.5s-10.3-14.9-19.3-16.3L378.1 154.8 309.5 13.5zM288 384.7V79.1l52.5 108.1c3.5 7.1 10.2 12.1 18.1 13.3l118.3 17.5L391 303c-5.5 5.5-8.1 13.3-6.8 21l20.2 119.6L299.2 387.5c-3.5-1.9-7.4-2.8-11.2-2.8z"
    />
  </svg>
);
const filledStar = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    <path
      fill="#00afef"
      d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
    />
  </svg>
);

let emptyIcon = "";
let halfIcon = "";
let filledIcon = "";

const ItemDetail = () => {
  const { id } = useParams();
  const [product, setProduct] = useState();
  const [products, setProducts] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [toggleCheckout, setToggleCheckout] = useState(false);
  useEffect(() => {
    getProductById();
    getProducts();
    getReviews();
  }, []);

  const getProductById = async () => {
    await axios
      .get(`${BASE_URL}product/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setProduct(response.data.product);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getProducts = async () => {
    await axios
      .get(`${BASE_URL}product/get_all_products`)
      .then((response) => {
        if (response.status === 200) {
          setProducts(response.data.products);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getReviews = async () => {
    await axios
      .get(`${BASE_URL}review/get_all_reviews`)
      .then((response) => {
        if (response.status === 200) {
          setReviews(response.data.reviews);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAverageRating();
  });
  const getAverageRating = async () => {
    const totalRating = reviews?.reduce(
      (acc, review) => acc + parseFloat(review.rating.$numberDecimal),
      0
    );
    setAverageRating(totalRating / reviews.length);
  };
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  const toggleShowHide = async () => {
    const rootElement = document.documentElement;
    if (toggleCheckout) {
      rootElement.style.height = "auto";
    } else {
      rootElement.style.height = "100vh";
    }
    setToggleCheckout((val) => !val);
  };

  const StarRating = ({ rating, type = "star" }) => {
    if (rating > 5) {
      rating = 5;
    }
    rating = 5 - rating;
    const wholePart = Math.floor(rating);
    const fractionalPart = rating - wholePart;

    const stars = [];

    if (type === "star") {
      emptyIcon = emptyStar;
      halfIcon = halfStar;
      filledIcon = filledStar;
    } else {
      emptyIcon = emptyStar;
      halfIcon = halfStar;
      filledIcon = filledStar;
    }

    for (let i = 0; i < wholePart; i++) {
      stars.push(
        <i key={i} className="star">
          {emptyIcon}
        </i>
      );
    }

    if (fractionalPart >= 0.25) {
      stars.push(
        <i key="half" className="star-half">
          {halfIcon}
        </i>
      );
    }

    while (stars.length < 5) {
      stars.push(
        <i key={stars.length} className="star">
          {filledIcon}
        </i>
      );
    }

    return (
      <div className="star-rating flex flex-row-reverse gap-0.5 justify-end">
        {stars}
      </div>
    );
  };

  const carouselItems = [
    {
      image: product?.coverImage,
    },
    {
      image: product?.coverImage,
    },
    {
      image: product?.coverImage,
    },
  ];

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path
              fill="#00afef"
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"
            />
          </svg>
        </span>
      </div>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <span>
          <svg
            className="rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="#00afef"
              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z"
            />
          </svg>
        </span>
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  const handleAddToCart = () => {
    // Validate quantity
    if (quantity < 1) {
      alert("Please select at least 1 quantity.");
      return;
    }

    // Validate date and time
    // if (!date || !time) {
    //   alert("Please select both date and time.");
    //   return;
    // }

    const cartItem = {
      ...product,
      quantity,
      time,
    };

    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];
    const existingProductIndex = existingCart.findIndex(
      (item) => item._id === cartItem._id
    );

    if (existingProductIndex !== -1) {
      // Update quantity if product already exists
      existingCart[existingProductIndex].quantity += cartItem.quantity;
    } else {
      // Add new product to cart
      existingCart.push(cartItem);
    }

    localStorage.setItem("cart", JSON.stringify(existingCart));

    const rootElement = document.documentElement;
    if (toggleCheckout) {
      rootElement.style.height = "auto";
    } else {
      rootElement.style.height = "100vh";
    }
    setToggleCheckout((val) => !val);
  };

  return (
    <div className="relative">
      {toggleCheckout && (
        <Checkout
          fun={toggleShowHide}
          quantity={quantity}
          setQuantity={setQuantity}
        />
      )}
      <Header />

      <div className="item-detail">
        <div className="container max-w-screen-xl mx-auto bg-white flex flex-wrap py-8 lg:py-16 gap-10 lg:gap-0">
          <div className="carousel-outer w-full lg:w-1/2 relative px-5">
            <Slider {...settings}>
              {carouselItems.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="flex justify-center">
                      <img
                        className="w-1/3 lg:w-3/5 h-auto"
                        src={item.image}
                        alt="bottle-img"
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="product-info w-full lg:w-1/2 mx-auto lg:mx-0 flex flex-col gap-4 px-4 xl:px-8">
            <div className="product-info-detail flex flex-col justify-start gap-4">
              <p className="product-name text-xl sm:text-3xl md:text-5xl uppercase font-semibold">
                {product?.name}
              </p>
              <p className="product-rating flex gap-1.5">
                <StarRating rating={averageRating} type="star" />
                <span className="text-base lg:text-lg font-semibold lg:font-bold">
                  {averageRating.toFixed(1)}
                </span>
                <span className="text-base lg:text-lg font-semibold lg:font-bold">
                  ({reviews.length})
                </span>
              </p>
              <div className="rounded-md add-to-cart-button price-view">
                <span>Rs: {product?.salePrice}</span>
              </div>
            </div>
            <div className="product-form w-full">
              <form onSubmit={(e) => e.preventDefault()}>
                <p className="uppercase font-semibold mb-2">Delivery</p>
                <div className="w-full flex flex-col sm:flex-row justify-between items-center gap-2 mb-4">
                  <input
                    type="date"
                    name="pick-date"
                    id="pick-date"
                    className="w-full input-field"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      value={time}
                      onChange={setTime}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          className="w-full sm:w-1/2 "
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <p className="uppercase font-semibold mb-2">Quantity</p>
                <div className="w-full h-auto relative mb-6">
                  <span
                    className="absolute z-10 cursor-pointer w-8 text-center hover:bg-gray-200 rounded-md top-2.5 left-3 text-2xl font-extrabold"
                    onClick={() => setQuantity((prev) => Math.max(prev - 1, 1))}
                  >
                    -
                  </span>
                  <input
                    type="number"
                    title="use buttons to change number"
                    name="pick-count"
                    id="pick-count"
                    className="relative w-full text-xl font-bold text-center input-field"
                    min={1}
                    value={quantity}
                    readOnly
                  />
                  <span
                    className="absolute z-10 cursor-pointer w-8 text-center hover:bg-gray-200 rounded-md top-2.5 right-3 text-2xl font-extrabold"
                    onClick={() => setQuantity((prev) => prev + 1)}
                  >
                    +
                  </span>
                </div>
                <button
                  type="button"
                  className="add-to-cart-btn w-full add-to-cart-button"
                  onClick={handleAddToCart}
                >
                  <span>Add to cart</span>
                </button>
              </form>
            </div>
            <div className="product-description-details text-justify sm:text-start mt-6">
              <div className="description mb-4">
                <p className="text-lg font-bold uppercase mb-2">Description</p>
                <p className="product-desc">{product?.description}</p>
              </div>
              {product?.details && (
                <div className="details">
                  <p className="text-lg font-bold uppercase mb-2">
                    Product Details
                  </p>
                  <ul>
                    {product.details.map((detail, index) => (
                      <li
                        key={index}
                        className="flex items-center gap-1.5 my-2"
                      >
                        <span>•</span>
                        <p className="product-detail">{detail}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Related Products section */}
        {products.length > 0 && (
          <div className="bottles py-8 lg:py-32">
            <p className="text-xl sm:text-3xl xl:text-5xl font-semibold xl:font-bold uppercase text-center mb-8">
              Related Products
            </p>
            <div className="container mx-auto flex flex-wrap justify-center gap-8">
              {products
                .filter((p) => p._id !== id)
                .slice(0, 4)
                .map((product) => (
                  <div
                    key={product._id}
                    className="bottle-card flex flex-col items-center w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4"
                  >
                    <img
                      src={product.coverImage}
                      alt={product.name}
                      className="w-full h-auto mb-4"
                    />
                    <p className="text-lg font-semibold mb-2">{product.name}</p>
                    <p className="text-base mb-4">Rs. {product.salePrice}</p>
                    <button
                      type="button"
                      className="bg-blue-500 text-white py-2 px-4 rounded"
                    >
                      {product.status ? "Add To Cart" : "Coming Soon"}
                    </button>
                  </div>
                ))}
            </div>
          </div>
        )}

        {/* Reviews section */}
        <div className="hear-from-community py-8 lg:py-32">
          <p className="text-xl sm:text-3xl xl:text-5xl font-semibold xl:font-bold uppercase text-center mb-8">
            Hear from the community
          </p>
          <div className="container mx-auto px-4 xl:px-0">
            <div className="comments">
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className="flex flex-col xl:flex-row my-4 py-8 border-t-2"
                >
                  <div className="min-w-40 opacity-55 flex justify-between items-center xl:block mb-2 xl:mb-0 border-b-2 xl:border-b-0">
                    <p className="text-xl font-semibold">
                      {review?.customer?.fullName}
                    </p>
                    <span className="uppercase text-xs font-light">
                      {review?.customer?.cityName}
                    </span>
                  </div>
                  <div className="flex-1">
                    <div className="w-full flex justify-between items-center mb-2">
                      <span className="comment-rating">
                        <StarRating rating={review?.rating?.$numberDecimal} />
                      </span>
                      <span className="comment-time text-xs lg:text-base font-light uppercase opacity-55">
                        {formatDistanceToNow(new Date(review?.updatedAt), {
                          addSuffix: true,
                        })}
                      </span>
                    </div>
                    <div className="w-full comment-content my-2">
                      <p className="font-light text-justify md:text-start">
                        {review?.comment}
                      </p>
                    </div>
                    {review?.imgs?.length > 0 && (
                      <div className="w-full images flex items-center gap-2 mt-4">
                        {review.imgs.map((imgSrc, imgIndex) => (
                          <img
                            key={imgIndex}
                            src={imgSrc}
                            alt="Review"
                            className="w-20 h-20 object-cover"
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ItemDetail;
