import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./style.css";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />

      <div className="refund-policy">
        <div className="contact-banner b w-full h-40 flex justify-center items-center">
          <p className="uppercase text-center text-white text-5xl font-extrabold">
            refund policy
          </p>
        </div>

        <div className="container mx-auto py-4 md:py-8 my-5 md:my-16">
          <div className="text-div w-full px-2 lg:px-8 flex flex-col gap-4">
            <div className="text">
              <p>
                At Al Fajar Water, we offer water delivery services. Once your
                order is sent out, it's considered final and can't be returned
                or exchanged.
              </p>
            </div>
            <div className="text">
              <p>
                If your product arrives damaged or faulty, we'll consider refund
                requests. If your package got damaged during delivery, contact
                us at info@alfajarwater.com within 5 days of getting it. Make
                sure to include your order number, describe the damage, and send
                photos.
              </p>
            </div>
            <div className="text">
              <p>
                We can't take responsibility if the product is used or tampered
                with after delivery.
              </p>
            </div>
            <div className="text">
              <p>
                To report a defective product, you must do so within 14 days of
                getting it. Email info@alfajarwater.com, and we'll help you
                further.
              </p>
            </div>
            <div className="text">
              <p>
                {" "}
                Make sure to use the same email address you used for your order
                and include your order ID number.
              </p>
            </div>
            <div className="text">
              <p>
                {" "}
                If you're not happy with your purchase, we'll try our best to
                fix it.
              </p>
            </div>
            <div className="text">
              <p>
                {" "}
                For issues with products bought from other sellers, like online
                or in stores, contact them directly. They'll handle it according
                to their return policy.
              </p>
            </div>
            <div className="text">
              <p>Our refund policy might change without warning.</p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default RefundPolicy;
