import React from "react";
import { Modal, Box } from "@mui/material";
import "./style.css"; // Import the CSS file

const TermsConditionsModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="privacy-policy-modal-title"
      aria-describedby="privacy-policy-modal-description"
    >
      <Box className="modal-box">
        <div id="privacy-policy-modal-description">
          <div className="privacy-policy">
            <div className="container mx-auto py-4 md:py-8 my-5 md:my-16">
              <div className="text-div w-full px-2 lg:px-8 flex flex-col gap-4">
                {/* <div className="text">
                  <span className="heading block">Introduction</span>
                  <p>Welcome to Al Fajar Water!</p>
                </div> */}
                <div className="text">
                  <p>
                    These comprehensive terms and conditions govern the use of
                    our products and services. By accessing or utilizing our
                    offerings, you agree to comply with these terms.
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Product Information</span>
                  <p>
                    At Al Fajar Water, our unwavering commitment lies in
                    delivering superior-quality mineral water sourced directly
                    from natural springs. We prioritize your well-being by
                    subjecting each of our products to stringent testing
                    protocols, guaranteeing unparalleled purity and safety.
                  </p>
                </div>
                <div className="text">
                  <p>
                    Our dedication to excellence ensures that every sip of Al
                    Fajar Water not only refreshes but also reassures, offering
                    you the peace of mind that comes from knowing you're
                    consuming water of the highest quality.{" "}
                  </p>
                </div>
                <div className="text">
                  <p>
                    Trust in Al Fajar Water for your hydration needs, where
                    quality and safety are paramount.
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Ordering and Delivery</span>
                  <p>
                    You can easily place your orders through our user-friendly
                    website or trusted authorized distributors. Please keep in
                    mind that delivery timelines might differ based on your
                    location and the availability of the desired products.
                  </p>
                </div>
                <div className="text">
                  <p>
                    We strive to ensure prompt delivery and will make every
                    effort to get your order to you as swiftly as possible. Your
                    satisfaction is our priority, and we appreciate your
                    patience as we work to fulfill your order efficiently and
                    effectively.
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Pricing and Payments</span>
                  <p>
                    At Al Fajar Water, transparency and fairness guide our
                    pricing policies. While we strive to offer competitive
                    prices, please note that they are subject to change without
                    prior notice. We require full payment before dispatching
                    your order, and we accept various secure payment methods,
                    including credit/debit cards and online transfers, to ensure
                    convenience and flexibility.{" "}
                  </p>
                </div>
                <div className="text">
                  <p>
                    Rest assured, your transaction details are safeguarded
                    through encryption and other security measures. If you have
                    any queries regarding pricing or payment options, our
                    customer service team is always available to assist you
                    promptly.{" "}
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Returns and Refunds</span>
                  <p>
                    We stand behind the quality of our products and offer
                    refunds or replacements for damaged or defective items.
                    Requests for refunds must be submitted within 7 days of
                    delivery, accompanied by proof of purchase.
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Intellectual Property</span>
                  <p>
                    At Al Fajar Water, we take great pride in the integrity of
                    our brand and the content we produce. All materials
                    showcased on our website and packaging are the sole property
                    of Al Fajar Water and are protected by copyright laws.{" "}
                  </p>
                </div>
                <div className="text">
                  <p>
                    Any unauthorized use or reproduction of our content without
                    prior written consent is strictly prohibited. We value
                    transparency and respect intellectual property rights. If
                    you wish to use our content for any purpose, please contact
                    us to obtain the necessary permissions.
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Liability</span>
                  <p>
                    We are committed to providing you with exceptional products
                    and services. While we strive for excellence in every
                    aspect, we acknowledge that unforeseen circumstances may
                    arise. Therefore, we want to clarify our liability policy.
                    Al Fajar Water is not liable for any damages or losses
                    resulting from the use of our products, except where
                    prohibited by law.{" "}
                  </p>
                </div>
                <div className="text">
                  <p>
                    We stand behind the quality and safety of our products, but
                    we cannot guarantee outcomes beyond our control. Your
                    satisfaction is our priority, and we appreciate your
                    understanding of our liability limitations as we continue to
                    serve you with integrity and transparency.
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Governing Law</span>
                  <p>
                    These terms and conditions are subject to the laws of
                    Pakistan, governing all interactions with Al Fajar Water. In
                    the event of any disputes arising from these terms, both
                    parties agree to resolve them through arbitration, adhering
                    to the legal framework of Pakistan.{" "}
                  </p>
                </div>
                <div className="text">
                  <p>
                    This commitment ensures fair and impartial resolution
                    processes, fostering transparency and trust between Al Fajar
                    Water and its customers. We value your confidence in our
                    products and services and are dedicated to upholding legal
                    standards while providing you with the best possible
                    experience.{" "}
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Modification of Terms</span>
                  <p>
                    We reserve the right to revise or amend these terms and
                    conditions at our discretion, without prior notice. Your
                    ongoing utilization of our products implies your acceptance
                    of any alterations implemented.
                  </p>
                </div>
                <div className="text">
                  <p>
                    At Al Fajar Water, we prioritize transparency and integrity,
                    endeavoring to keep you informed of any modifications to our
                    policies. Your continued patronage signifies your agreement
                    to abide by the updated terms and conditions. We appreciate
                    your understanding and cooperation as we strive to enhance
                    our services and uphold the highest standards of excellence.
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Contact Information</span>
                  <p>
                    For inquiries or assistance, please don't hesitate to reach
                    out to our friendly customer service team at
                    info@alfajarwater.com.
                  </p>
                </div>

                <div className="text">
                  <span className="heading block">Agreement</span>
                  <p>
                    When you purchase and use Al Fajar Water products, you
                    affirm that you have thoroughly read, comprehended, and
                    consented to adhere to the terms and conditions outlined
                    herein. We appreciate your diligence in reviewing these
                    provisions, as they govern your interactions with our
                    products and services.
                  </p>
                </div>
                <div className="text">
                  <p>
                    Your acknowledgment signifies your commitment to abiding by
                    the rules and regulations set forth by Al Fajar Water. We
                    value your trust and strive to provide you with
                    superior-quality products and exceptional service.
                  </p>
                </div>
                <div className="text">
                  <p>
                    Thank you for your continued support, and we look forward to
                    serving you responsibly and ethically.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default TermsConditionsModal;
