import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./style.css";

const Accessibility = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <Header />

      <div className="accessibility">
        <div className="contact-banner b w-full h-40 flex justify-center items-center">
          <p className="uppercase text-center text-white text-5xl font-extrabold">
            OUR DEDICATION TO ACCESSIBILITY
          </p>
        </div>

        <div className="container mx-auto py-4 md:py-8 my-5 md:my-16">
          <div className="text-div w-full px-2 lg:px-8 flex flex-col gap-4">
            <div className="text">
              <p>
                At Al Fajar Water, we're dedicated to ensuring everyone can
                easily use our website. If you're having trouble accessing or
                navigating our site, or if you think something isn't fully
                accessible for people with disabilities, please get in touch
                with our Customer Service team.
              </p>
            </div>
            <div className="text">
              <p>
                You can call us at 111-111-901 or email us at
                info@alfajarwater.com with the subject line "Accessibility
                Help". Let us know what part of the website you're having
                trouble with or any suggestions you have for improvement.
              </p>
            </div>
            <div className="text">
              <p>
                We take your feedback seriously and will use it to make our
                website more accessible to all our customers. While we can't
                control third-party content, we encourage them to prioritize
                accessibility too. Together, we can ensure everyone can enjoy Al
                Fajar Water online.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Accessibility;
